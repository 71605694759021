.news_info_card_wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  position: absolute;
  /* top: 401px; */
}
.news_info_wrapper {
  width: 90%;
  margin: auto;
}

/* .news_info_card_wrapper .news_content_table {
  flex-grow: 1;
} */

.news_content_table.news_card {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.news_content_table.news_card p p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.news_bottom {
  width: 100%;
  height: 300px;
  margin-bottom: 5%;
}
