.wrapper_img_partners {
  display: flex;
  margin-bottom: 10%;
}
.custom-partners-wrapper-head {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 90%;
  margin: auto;
  position: relative;
  top: -700px;
}
.custom-partners-wrapper-head p {
  width: 90%;
  margin: auto;
  position: absolute;
  text-align: right;
  right: 0;
  top: 100px;
  color: white;
}
.custom-partners-wrapper-head p * {
  color: white !important;
}
.custom-partners-wrapper-head h3 {
  font-size: 32px;
  position: absolute;
  top: -150px;
  left: 0;
  color: white;
}

.custom-partners-tabs {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  flex-wrap: wrap;
  align-content: flex-start;
}
.partners {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 350px;
  gap: 20px;
}
.partners p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.partners h4 {
  font-size: 20px;
  text-align: center;
}
.partners img {
  width: 250px;
  height: 250px;
}

#custom-page {
  margin: 0 auto;
  min-height: 100vh;
  /* margin-top: 100px; */
  color: black;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  background-color: #f2f2f2;
}

.custom-partners-tabs {
  padding-top: 40px;
  width: 90%;
}

.custom-partners-tabs-nav {
  list-style: none;
  padding: 0;
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.custom-partners-tabs-nav__link {
  text-decoration: none;
  color: black;
  opacity: 0.7;
  margin-right: 25px;
  padding: 10px 15px;
  height: 50px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 0px;
}

.custom-partners-tabs-nav__link.is-active {
  opacity: 1;
  border-bottom: 2px solid black;
}
.partner_main_picture_wrapper {
  width: 100%;
  height: 1000px;
}
.partner_main_picture_wrapper img {
  width: 100%;
  position: absolute;
  top: 0px;
  filter: brightness(75%);
}
.partners_modal {
  display: none;
}
.partners_modal.open {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 55%;
  margin: auto;
  background-color: #f2f2f2;
  padding: 26px;
  left: 23%;
  height: 500px;
  top: 40%;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  z-index: 10;
}

/* .partners_modal.open::before {
  content: "";
  position: absolute;
  width: 100vw;
  height: 100vh;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  z-index: -10;
} */
.partners_modal.open p {
  width: 60%;
  margin: auto;
}

.partner_modal_img {
  width: 25%;
}

.partners_modal.open button {
  position: relative;
  left: -370px;
  top: -428px;
  width: 35px;
  height: 35px;
  border: none;
  background-color: transparent;
  z-index: 10;
}

.partners_modal.open button img {
  position: absolute;
  width: 100%;
}

/**
 * Tab
 */
.custom-partners-tab {
  display: none;
}

.custom-partners-tab.is-active {
  display: block;
}

.custom-partners-tab__content {
  padding: 20px;
  padding-left: 10px;
  position: relative;
  animation: showTab 300ms ease-in-out both;
}

@keyframes showTab {
  from {
    opacity: 0;
    top: 5px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

.custom-partners-tabs-nav .is-active {
  /* background-color: rgba(128, 128, 128, 0.508); */
  transition: 0.5s;
}

.custom-partners-tab-nav-marker {
  min-height: 4px;
  background-color: #fff;
  position: absolute;
  bottom: -10px;
  transition: all 0.3s ease-out;
}

.custom-partners-tab-content-img {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.custom-partners-tab-content-img div img {
  width: 300px;
  margin-bottom: 5%;
}

.custom-partners-tab-content-img div {
  display: flex;
  flex-direction: column;
  margin: 1%;
}
