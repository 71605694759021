.history_company {
  height: 640px;
  position: relative;
}
.history_company_wrapper_content h4 {
  font-size: 26px;
}
.history_company_wrapper_img h2 {
  font-size: 32px;
}

.content_other {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  width: 90%;
  margin: 50px auto 0;
}
.content_other h4 {
  font-size: 32px;
}
.history_other_content {
  margin-top: 50px !important;
  width: 90%;
  margin: auto;
}
