.main_block_contacts {
  width: 95%;
  margin: auto;
  height: 250px;
  display: flex;
  align-items: center;
  margin-right: 0;
  background: #d3d3d3;
}

.main_block_contacts h2 {
  font-size: 42px;
  margin-left: 30px;
}

.contacts_wrapper_two_block {
  width: 95%;
  margin: auto;
  height: 350px;
  display: flex;
  align-items: center;
  margin-right: 0;
  margin-top: 100px;
}

.address_block {
  margin-top: 24px;
}

.contacts_left_block h3 {
  font-size: 23px;
}

.address_block ul {
  line-height: 36px;
}

.address_block ul li {
  display: flex;
  align-items: center;
  font-size: 20px;
}

.contacts_left_block {
  width: 35%;
}

.address_block ul li img {
  margin-right: 10px;
}

.address_block ul li:nth-child(2),
.address_block ul li:nth-child(3) {
  margin-left: 34px;
}

.address_block ul li:nth-child(3) {
  width: 50%;
}

.contacts_right_block {
  width: 65%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.contacts_right_block ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 36px;
}

.contacts_right_block ul li {
  font-size: 20px;
}

.contacts_right_block ul li a {
  margin-left: 25px;
  color: #294c63;
}

.contacts_right_block img {
  width: 100%;
  background-size: cover;
}

.img_block {
  height: 450px;
  width: 95%;
  margin: auto;
  margin-right: 0;
  display: flex;
  align-items: center;
  position: relative;
}

.img_block::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.382);
}

.contacts_img_content_wrapper {
  margin-top: 100px;
  margin-bottom: -100px;
}

.contacts_img_content_wrapper p {
  font-size: 40px;
  font-weight: 900;
  width: 40%;
  left: 40px;
  color: white;
  position: absolute;
  z-index: 3;
  line-height: 60px;
}

@media screen and (max-width: 1024px) {
  .contacts_right_block img {
    height: 100%;
    width: auto;
    background-position: center;
    background-size: cover;
  }
  .contacts_left_block {
    width: 40%;
  }
  .contacts_right_block {
    width: 60%;
  }
}

@media screen and (max-width: 682px) {
  .contacts_wrapper_two_block {
    flex-direction: column;
    height: 600px;
    width: 100%;
  }
  .contacts_left_block {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .address_block ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contacts_right_block {
    width: 100%;
    margin-top: 20px;
  }
  .main_block_contacts {
    width: 100%;
    justify-content: center;
  }
  .img_block {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .contacts_img_content_wrapper {
    margin-bottom: -300px;
  }
  .address_block ul li {
    text-align: center;
  }
}
