.loader {
  margin: 80px auto;
  height: 100vh;
  width: 100%;
  text-align: center;
  padding: 10% 0;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top;
  padding-top: 20%;
}

/*
  Set the color of the icon
*/
.loader svg path,
svg rect {
  fill: #ff6700;
}
.loader_container {
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  position: absolute;
  top: 0;
  z-index: 999999999;
  padding-top: 25%;
}

.loader_img img {
  width: 400px;
  height: 200px;
}
