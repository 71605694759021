@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@1,400;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container div {
  height: 70%;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.container div img {
  width: 100vw;
}

.container div h1 {
  font-size: 150px;
  -webkit-text-stroke: 4px rgba(0, 0, 0, 0.486);
  -webkit-text-fill-color: white;
  font-family: RaceSport;
  font-weight: 700;
  text-shadow: 8px 8px 2px rgba(0, 0, 0, 0.836);
}

.container div p {
  font-size: 25px;
  font-family: RaceSport;
  font-weight: 700;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

@import url("https://fonts.googleapis.com/css?family=Fira+Mono:400");

.PageNotFound {
  animation: glitch 1s linear infinite;
}

@keyframes glitch {
  2%,
  64% {
    transform: translate(2px, 0) skew(0deg);
  }
  4%,
  60% {
    transform: translate(-2px, 0) skew(0deg);
  }
  62% {
    transform: translate(0, 0) skew(5deg);
  }
}

.PageNotFound:before,
.PageNotFound:after {
  content: attr(title);
  position: absolute;
  left: 0;
}

.PageNotFound:before {
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop {
  2%,
  64% {
    transform: translate(2px, -2px);
  }
  4%,
  60% {
    transform: translate(-2px, 2px);
  }
  62% {
    transform: translate(13px, -1px) skew(-13deg);
  }
}

.PageNotFound:after {
  animation: glitchBotom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchBotom {
  2%,
  64% {
    transform: translate(-2px, 0);
  }
  4%,
  60% {
    transform: translate(-2px, 0);
  }
  62% {
    transform: translate(-22px, 5px) skew(21deg);
  }
}
