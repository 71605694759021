.news_wrapper {
  margin: 5% auto 0;
  width: 90%;

  height: 490px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
}

.news_info_wrapper {
  display: flex;
}

.news_wrapper::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.238);
}

.news_content_table {
  position: relative;
  width: 474.8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: white;
  transition: transform 0.2s ease;
  max-height: 200px;
  height: 150px;
  cursor: pointer;
}

.news_content_item {
  max-width: 300px;
  padding: 5px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  position: absolute;
  left: 15px;
}

.news_content_item p {
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.news_content_item p {
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.news_content_item h3 {
  font-size: 23px;
}

.news_content_item p {
  font-size: 20px;
}

.news_content_item p p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.news_content_item.news_card p p {
  text-align: left;
}

.news_content_overlay {
  position: absolute;
  bottom: -200px;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 2;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.news_content_overlay.leave {
  animation: slideDown 1s ease;
  bottom: -200px;
}

@keyframes slideDown {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}

.news_content_item.news_card * {
  cursor: pointer;
}
.news_content_table button {
  cursor: pointer;
}

.news_content_table:hover .news_content_overlay {
  height: 150px;
  transform: translateY(-50px);
  z-index: 1;
}

.news_content_table button {
  background-color: #1d1d1b;
  color: white;
  width: 100px;
  border: none;
  height: 50px;
  position: absolute;
  right: 15px;
}

.news_content_table:hover {
  transform: translateY(-150px);
}
.news_bottom_block {
  width: 100%;
  height: 150px;
  position: relative;
  z-index: 10;
  background-color: #f2f2f2;
}
