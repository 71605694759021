/* !footer start */

.footer {
  background-color: #1d1d1b;
  padding: 50px 20px 0 20px;
  color: white;
  position: relative;
  height: 40vh;
  bottom: 0;
}
.footer_content_left,
.footer_content_bottom {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  line-height: 30px;
}

.footer_content_top {
  display: flex;
  justify-content: space-between;
}

.footer_content_top h4 {
  margin: 0 20px;
}
.footer_content_top p,
.footer_content_bottom p,
.footer_block p {
  font-size: 14px;
  color: #fdfdffbb;
  margin: 0 20px;
}
.footer hr {
  color: white;
  background-color: white;
  height: 3px;
  margin: 20px 0;
}
.footer_block {
  margin-top: 40px;
}

.footer_block p {
  word-spacing: 5px;
}

.footer_content_right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer_content_right button {
  width: 100px;
  height: 40px;
  background-color: transparent;
  border: 2px solid #f1f1f2;
  color: white;
  padding: 10px;
}

.footer_content_right img {
  width: 35px;
  filter: brightness(10);
}
