.section_head_content {
  width: 90%;
  height: 160px;
  margin: auto;

  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}

.section_head_content h2 {
  font-size: 34px;
}

.section_head_content p {
  font-size: 20px;
}

.carousel_section {
  width: 90%;
  margin: auto;
}
.carousel_section .mySwiper {
  margin-bottom: 0;
}

#carousel_company_swiper {
  width: 90%;
  margin: auto;
  margin-bottom: 150px;
}

.carouselImgWrapper img {
  width: 57%;
  left: 41%;
  top: 10%;
  background-color: #f1f1f2;
}

.carouselWrapperItems {
  height: 60%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  /* top: -80px; */
}
.carouselWrapperItem_mobile {
  display: none;
}
.swiper-button-prev.swiper-button-disabled {
  position: absolute;
  top: 65%;
  right: 7.53%;
  z-index: 10;
  z-index: 10;
  width: 40px;
  height: 30px;
  /* transform: rotate(45deg); */
  transform: rotate(0) !important;
  /* background-color: #284c63;*/
  background-color: rgb(51, 51, 51);
  border: none;
  border-right: 2px solid white;
  /* border-top: 5px solid rgb(51, 51, 51);
  border-right: 5px solid rgb(51, 51, 51); */
  cursor: pointer;
}
.swiper-button-prev {
  position: absolute;
  top: 65%;
  right: 7.53%;
  z-index: 10;
  width: 40px;
  height: 30px;
  /* transform: rotate(45deg); */
  transform: rotate(0) !important;
  /* background-color: #284c63;*/
  background-color: black;
  border: none;
  border-right: 2px solid white;
  /* border-top: 5px solid rgb(51, 51, 51);
  border-right: 5px solid rgb(51, 51, 51); */
  cursor: pointer;
}
.swiper-button-next {
  position: absolute;
  top: 65%;
  right: 5%;
  z-index: 10;
  width: 40px;
  height: 30px;
  /* transform: rotate(45deg); */
  transform: rotate(0) !important;
  border: none;
  border-left: 2px solid white;
  background-color: black;
  /* border-top: 5px solid black;
  border-right: 5px solid black; */
  cursor: pointer;
}
.swiper-button-next.swiper-button-disabled {
  position: absolute;
  top: 65%;
  right: 5%;
  z-index: 10;
  width: 40px;
  height: 30px;
  /* transform: rotate(45deg); */
  transform: rotate(0) !important;
  /* background-color: #284c63;*/
  background-color: rgb(51, 51, 51);
  border: none;
  border-left: 2px solid white;
  /* border-top: 5px solid rgb(51, 51, 51);
  border-right: 5px solid rgb(51, 51, 51); */
  cursor: pointer;
}

.carouselContent {
  width: 30%;
  text-align: left;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  align-self: flex-start;
}
.carouselContent p {
  text-align: left;
}
.carouselContent p:first-child {
  font-size: 23px;
}
