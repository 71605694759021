html,
body {
  position: relative;
}

body {
  background: #7e7e7e;
  font-family: RaceSport;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  /* margin-top: 5%; */
  width: 100%;

  height: 595px;

  font-family: RaceSport;
  font-weight: 500;
  background-color: #f2f2f2;
  margin-bottom: 60px;
}

.swiper-vertical > .swiper-wrapper {
  align-items: flex-end;
}

.swiper-wrapper {
  background-color: transparent;
  margin: 0 auto;
  width: 100%;
  left: 0%;
  position: relative;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 78%;
}
.swiper-slide img {
  display: block;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 855px;
  filter: brightness(50%);
}
.swiper-pagination-bullet {
  background: transparent;
  --swiper-theme-color: rgb(0, 0, 0);
  color: black;
  height: auto;
}
.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  position: relative;
  height: auto;
  width: auto;
}

@keyframes slideBorder {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.swiper-pagination-bullet-active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: auto;
  height: 2px;
  background-color: #1d1d1b;
  animation: slideBorder 0.3s ease-in-out forwards;
  vertical-align: bottom;
}

.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: var(--swiper-pagination-bullet-vertical-gap, 30px) 7px;
  height: auto;
  margin-top: -1%;
}
.swiper-pagination {
  max-width: 18%;
  height: 70vh;
  display: flex;
  flex-direction: column;
}
.swiper-pagination span {
  width: 100%;
  font-size: 20px;
  text-align: start;
}

.swiper-pagination-vertical.swiper-pagination-bullets,
.swiper-vertical > .swiper-pagination-bullets {
  right: 77%;
  height: 430px;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-vertical {
  right: 65%;
  height: 500px;
  top: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 23px;
  /* gap: 50px; */
  width: 34%;
  align-items: flex-start;
  text-align: left;
  max-width: 50%;
}

/* NAVBAR */
a {
  text-decoration: none;
  color: black;
  transition: 0.2s;
}
ul {
  list-style-type: none;
  padding-left: 0;
}

#about_video {
  width: 100%;
}

#about_video::before {
  content: "Scroll Down";
  position: absolute;
  left: 45%;
  color: black;
}
.swiper_slide {
  background-color: #f1f2f2;
}
.about_wrapper_img {
  height: 75vh;
  position: relative;
}
.about_conteiner {
  width: 90%;
  margin: auto;
}
.about_img_block {
  width: 90%;
  margin: auto;
  height: 80%;
}

.about_img_block h3 {
  width: auto;
  left: 8%;
  position: absolute;
  top: 17%;
  font-size: 43px;
}

.about_img_content {
  position: absolute;
  right: 8%;
  top: 50%;
  height: 55%;
  width: 44%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  gap: 30px;
}

.about_img_content h4 {
  font-size: 23px;
}

.about_img_content p {
  width: 100%;
}
.swiper_slide {
  position: relative;
  width: 63%;
}

.mission_content {
  position: relative;
  z-index: 1;
  color: white;
}

.mission_content h4 {
  color: white;
  font-size: 52px;
}

.mission_content p p {
  color: white;
  font-size: 23px;
}

.mission_content p {
  color: white;
}

.mission-item img {
  position: absolute;
  top: 65px;
  left: -45px;
  width: 102%;
  height: 73%;
  filter: brightness(80%);
}
