@keyframes ticker-kf {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-1000vw);
  }
}

.overflow-hidden {
  overflow: hidden;
  margin-bottom: 50px;
}

.img-ticker {
  animation: ticker-kf 120s linear infinite;
  display: flex;
  align-items: center;
}

.img-ticker img {
  width: 150px;
  height: auto;
  margin: 0 60px;
}
