#page {
  margin: 0 auto;
  /* margin-top: 100px; */
  color: black;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  background-color: #f2f2f2;
}

#tabs {
  padding-top: 40px;
  width: 90%;
}

.c-tabs-nav {
  list-style: none;
  padding: 0;
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  left: -23px;
}

.c-tabs-nav__link {
  text-decoration: none;
  color: black;
  opacity: 0.7;

  margin-right: 25px;
  padding: 0 15px;

  height: auto;

  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 0px;
}

.c-tabs-nav__link.is-active {
  opacity: 1;
  border-bottom: 2px solid black;
}

/**
 * Tab
 */
.c-tab {
  display: none;
}

.c-tab.is-active {
  display: block;
}

.c-tab__content {
  padding: 20px;
  padding-left: 10px;
  position: relative;
  animation: showTab 300ms ease-in-out both;
}

@keyframes showTab {
  from {
    opacity: 0;
    top: 5px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

.c-tabs-nav .is-active {
  transition: 0.5s;
}

.c-tab-nav-marker {
  min-height: 4px;
  background-color: #fff;
  position: absolute;
  bottom: -10px;

  transition: all 0.3s ease-out;
}

.c-tab_content-img {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.c-tab_content-img div img {
  width: 300px;
  margin-bottom: 5%;
}

.c-tab_content-img div {
  display: flex;
  flex-direction: column;
  margin: 1%;
}
