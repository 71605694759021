.resume_upload {
  height: 300px;
}
.content_resume_wrapper {
  height: 600px;
  margin-top: 80px;
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  margin-top: 20px;
}
.content_resume_wrapper div:nth-child(2) {
  border: 1px solid black;
  padding: 20px;
  width: 70%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
}

.content_resume_wrapper div:nth-child(2) p {
  font-size: 32px;
}
