.stats_wrapper_about {
  width: 90%;
  height: 100px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.stats_wrapper_about h3 {
  font-size: 30px;
}

.stats_wrapper_about p {
  font-size: 20px;
}

.history_company {
  width: 90%;
  height: 525px;
  margin: 5% auto;
}
.history_company_wrapper_img {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  width: 100%;
  height: 770px;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  color: white;
}

.history_company_wrapper_img * {
  z-index: 5;
  color: white !important;
}

.history_company_wrapper_img::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #1b1b1daf;
  z-index: 1;
}
.history_company_wrapper_content * {
  color: white;
}

/* 
.history_company_wrapper_img::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.349);
} */
.history_company_wrapper_img h2 {
  margin-top: 8%;
  margin-left: 8%;
  color: white;
}

.history_company_wrapper_content {
  width: 75vw;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 20px;
  position: relative;
  right: -20%;
  top: -30px;
  text-align: right;
}
.history_company_wrapper_content p {
  text-align: right;
}
.about_content {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  align-items: center;
}

.about_content h4 {
  font-size: 35px;
}

.about_content p p {
  width: 80%;
  margin: auto;
}

.about_page_haed_item {
  width: 70%;
  height: 90vh;
  display: flex;
  margin: 100px auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.about_page_haed_item h3 {
  font-size: 32px;
  margin-bottom: 30px;
}

.about_page_haed_item p {
  font-size: 20px;
}

.company_philosophy_first_top_block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  align-items: center;
  padding: 100px 0 50px;
  border-bottom: 5px solid white;
}
.company_philosophy {
  padding-bottom: 50px;
  margin-bottom: 100px;
}
.company_philosophy_first_top_block h3 {
  font-size: 32px;
  text-align: center;
  width: 70%;
}

.company_philosophy_first_top_block p {
  font-size: 23px;
  text-align: center;
  width: 70%;
}

.company_philosophy_fist_bottom_block {
  display: flex;
  border-top: 5px solid white;
}

.company_philosophy_left_block {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 30px 35px 70px 30px;
  border-right: 5px solid white;
}

.company_philosophy_left_block h4 {
  width: 70%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 30px;
}

.company_philosophy_left_block p {
  width: 70%;
  text-align: center;
  font-size: 20px;
}

.company_philosophy_right_block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  border-left: 5px solid white;
}
.company_philosophy_top_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  height: 50%;
  padding: 30px;
  border-bottom: 5px solid white;
}
.company_philosophy_top_block h6 {
  width: 70%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  letter-spacing: 7px;
}

.company_philosophy_top_block h5 {
  width: 70%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 32px;
}
.company_philosophy_top_block p {
  text-align: center;
  width: 70%;
  font-size: 18px;
}
.company_philosophy_bottom_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50%;
  padding: 30px;
  padding: 30px 30px 70px 30px;
  border-top: 5px solid white;
}

.company_philosophy_bottom_block h4 {
  width: 70%;
  font-size: 32px;
  letter-spacing: 7px;
  text-align: center;
}
.company_philosophy_bottom_block p {
  width: 70%;
  font-size: 27px;
  text-align: center;
}
